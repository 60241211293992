import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CookieName } from "./common/utils/CookieUtils";

/**
 * The shadwork default page doesn't include render because the scope of page is to redirect
 * while loading to home if previously wasn't logged in or to dashboard page if local storage
 * includes an authorisation token.
 */
const ShadworkPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const documentLocation = Cookies.get(CookieName.DOCUMENT_LOCATION);
      navigate(documentLocation ? documentLocation : "/dashboard");
    } else {
      navigate("/home");
    }
  });
};

export default ShadworkPage;
