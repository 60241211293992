import { createSlice } from "@reduxjs/toolkit";

export const partnershipSlice = createSlice({
  name: "partnership",
  initialState: {
    accounts: undefined,
    contacts: undefined,
    requests: undefined,
  },
  reducers: {
    addAccounts(state, action) {
      if (state.accounts === undefined) {
        state.accounts = action.payload;
      } else {
        state.accounts = [...state.accounts, action.payload];
      }
    },
    excludeAccount(state, action) {
      if (state.accounts !== undefined) {
        state.accounts = state.accounts.filter(
          (account) => account.uuid !== action.payload
        );
      }
    },
    addContacts(state, action) {
      if (state.contacts === undefined) {
        state.contacts = action.payload;
      } else {
        state.contacts = [...state.contacts, action.payload];
      }
    },
    addContact(state, action) {
      if (state.contacts === undefined) {
        state.contacts = [action.payload];
      } else {
        state.contacts.push(action.payload);
      }
    },
    excludeContact(state, action) {
      if (state.contacts !== undefined) {
        state.contacts = state.contacts.filter(
          (contact) => contact.uuid !== action.payload
        );
      }
    },
    addRequests(state, action) {
      if (state.requests === undefined) {
        state.requests = action.payload;
      } else {
        state.requests = [...state.requests, action.payload];
      }
    },
    excludeRequest(state, action) {
      if (state.requests !== undefined) {
        state.requests = state.requests.filter(
          (request) => request.uuid !== action.payload
        );
      }
    },
  },
});

export const {
  addAccounts,
  excludeAccount,
  addContacts,
  addContact,
  excludeContact,
  addRequests,
  excludeRequest,
} = partnershipSlice.actions;

export default partnershipSlice.reducer;
