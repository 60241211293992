import { NavLink, useLocation } from "react-router-dom";
import ContactsIcon from "@mui/icons-material/Contacts";
import { Path } from "../../partnership/utils/PathUtils";
import styles from "../style/option.module.css";

const ContactOption = () => {
  const location = useLocation();

  const isActive = () => {
    return location.pathname.startsWith(Path.BASE);
  };

  return (
    <NavLink
      to={Path.GENERIC}
      onClick={(event) => {
        if (isActive()) {
          event.preventDefault();
        }
      }}
      className={() => {
        const stateStyle = isActive() ? styles.active : styles.inactive;
        return `${styles.option} ${stateStyle}`;
      }}
    >
      <ContactsIcon />
      contacts
    </NavLink>
  );
};

export default ContactOption;
