export const phraseMatches = (value, phrase) => {
  // divide phrase and account name into tokens and
  // then filter accounts which matches to the text phrase
  const phraseTokens = phrase.toLowerCase().split(" ");
  const valueTokens = value.toLowerCase().split(" ");
  return valueTokens.some((valueToken) => {
    return phraseTokens.some((phraseToken) => {
      return valueToken.includes(phraseToken);
    });
  });
};
