import { NavLink } from "react-router-dom";
import ListItemView from "./ListItemView";
import { Path as RegistrationPath } from "../../../registration/utils/PathUtils";
import styles from "../../style/pricing.module.css";

const EnterprisePlanView = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#7FC6E6" }} className={styles.panel} />
      <div
        className={styles.planContent}
        style={{
          borderLeft: "2px solid #7FC6E6",
          borderRight: "2px solid #7FC6E6",
          borderBottom: "2px solid #7FC6E6",
        }}
      >
        <label
          className={styles.planLabel}
          style={{
            backgroundColor: "#7FC6E6",
            width: "10vw",
          }}
        >
          Enterprise
        </label>
        <div
          style={{ marginTop: "2vh", display: "flex", flexDirection: "column" }}
        >
          <label
            style={{ color: "#0D6986", fontSize: "1vw", fontWeight: "bold" }}
          >
            Billed annually. A flexible pricing
          </label>
          <label
            style={{ color: "#0D6986", fontSize: "1vw", fontWeight: "bold" }}
          >
            plan to exactly fit business requirements.
          </label>
        </div>
        <label className={styles.planMessage}>
          A fully customizable solution tailored to your unique requirements,
          optimizing resources based on your specific needs. Access to all
          features with no limitations.
        </label>
        <NavLink to={RegistrationPath.PROFILE} className={styles.startedButton}>
          Get Started for Free
        </NavLink>
        <div
          style={{
            marginTop: "3vh",
            marginBottom: "3vh",
            border: "0.5px solid #0D6986",
          }}
        />
        <label
          style={{
            color: "#0D6986",
            fontSize: "1.1vw",
            fontWeight: "bold",
            marginBottom: "1vh",
          }}
        >
          Just a brief selection of what's available
        </label>
        <ListItemView color={"#7FC6E6"}>
          catalog and markdown management
        </ListItemView>
        <ListItemView color={"#7FC6E6"}>
          volume purchase configuration
        </ListItemView>
        <ListItemView color={"#7FC6E6"}>
          bulk ordering and discounts
        </ListItemView>
        <ListItemView color={"#7FC6E6"}>reordering and schedulers</ListItemView>
        <ListItemView color={"#7FC6E6"}>
          client system and ERP integration
        </ListItemView>
        <ListItemView color={"#7FC6E6"}>
          advanced search capabilities and recommendations
        </ListItemView>
      </div>
    </div>
  );
};

export default EnterprisePlanView;
