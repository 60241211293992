import { NavLink } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styles from "../style/option.module.css";

const InfoOption = () => {
  return (
    <NavLink to={"#"} className={`${styles.option} ${styles.inactive}`}>
      <InfoOutlinedIcon />
      info
    </NavLink>
  );
};

export default InfoOption;
