import { NavLink, useLocation } from "react-router-dom";
import { Path } from "../utils/PathUtils";
import styles from "../style/registration.module.css";

const NavigationControl = (props) => {
  const location = useLocation();

  const prevLink = () => {
    if (location.pathname === Path.ADDRESS) {
      return Path.PROFILE;
    } else if (location.pathname === Path.ADMIN) {
      return Path.ADDRESS;
    } else if (location.pathname === Path.SUBMIT) {
      return Path.ADMIN;
    } else {
      return "#";
    }
  };

  const forwardLabel = () => {
    if (location.pathname === Path.SUBMIT) {
      return "submit";
    } else {
      return "next";
    }
  };

  const nextLink = () => {
    if (location.pathname === Path.PROFILE) {
      return Path.ADDRESS;
    } else if (location.pathname === Path.ADDRESS) {
      return Path.ADMIN;
    } else if (location.pathname === Path.ADMIN) {
      return Path.SUBMIT;
    } else {
      return "#";
    }
  };

  return (
    <div className={styles.buttonNavGroup}>
      <NavLink
        to={prevLink()}
        className={styles.button}
        onClick={props.onClick}
        style={{
          backgroundColor:
            location.pathname === Path.PROFILE ? "white" : "#0d6986",
        }}
      >
        back
      </NavLink>
      <NavLink
        to={nextLink()}
        className={styles.button}
        onClick={(event) =>
          props.onClick(event, location.pathname === Path.SUBMIT)
        }
      >
        {forwardLabel()}
      </NavLink>
    </div>
  );
};

export default NavigationControl;
