import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import SubjectIcon from "@mui/icons-material/Subject";
import IconButton from "@mui/material/IconButton";
import EmptyPageView from "../component/EmptyPageView";
import { addAccounts, excludeAccount } from "../redux/partnershipSlice";
import {
  fetchAccounts,
  sendEstablishContactRequest,
} from "../client/AxiosClient";
import { phraseMatches } from "../utils/SearchUtils";
import {
  showLoading,
  hideLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { Transition } from "../utils/EffectUtils";
import styles from "../style/account.module.css";

const AccountControlButton = (props) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  const onDialogClose = () => {
    setActive(false);
  };

  const onContactRequest = () => {
    const { uuid } = props.account;
    showLoading();
    onDialogClose();
    sendEstablishContactRequest(uuid)
      .then(() => dispatch(excludeAccount(uuid)))
      .finally(hideLoading);
  };

  return (
    <div>
      <IconButton
        onClick={() => {
          setActive(true);
        }}
      >
        <SubjectIcon htmlColor="#0D6986" />
      </IconButton>
      <Dialog
        open={active}
        TransitionComponent={Transition}
        keepMounted
        onClose={onDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Proceed with connection request</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`You're about to send a connection request to "${props.account.name}". Once sent, 
            this request cannot be undone. The account will be notified and can decide whether
            to accept or decline your connection request.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose}>Dismiss</Button>
          <Button onClick={onContactRequest}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AccountPage = () => {
  const dispatch = useDispatch();
  const { accounts } = useSelector((state) => state.partnership);
  const { phrase } = useSelector((state) => state.search);

  useEffect(() => {
    if (!accounts) {
      showLoading();
      fetchAccounts()
        .then((data) => dispatch(addAccounts(data)))
        .finally(hideLoading);
    }
  });

  if (accounts) {
    const actualAccounts = accounts.filter((account) => {
      if (phrase !== undefined) {
        return phraseMatches(account.name, phrase);
      } else {
        return true;
      }
    });
    if (actualAccounts.length > 0) {
      return (
        <Grid container columns={4} spacing={3}>
          {accounts
            .filter((account) => {
              if (phrase !== undefined) {
                return phraseMatches(account.name, phrase);
              } else {
                return true;
              }
            })
            .map((account) => (
              <Grid item xs={1} key={account.uuid}>
                <div className={styles.content}>
                  <div className={styles.control}>
                    <AccountControlButton account={account} />
                  </div>
                  <div className={styles.logo}>
                    <img className={styles.image} src={account.logo} alt={""} />
                  </div>
                  <label className={styles.name}>{account.name}</label>
                </div>
              </Grid>
            ))}
        </Grid>
      );
    } else {
      return (
        <EmptyPageView
          message={`It looks like there are no accounts available to connect with at the moment.
          Please check back later or explore other ways to expand your network.`}
        />
      );
    }
  }
};

export default AccountPage;
