export const isEmpty = (source) => {
  return source === undefined || source.length === 0;
};

export const isNotEmpty = (source) => {
  return !isEmpty(source);
};

export const isValidEmail = (source) => {
  return /.+@.+\.[A-Za-z]+$/.test(source);
};

export const isValidAccountName = (source) => {
  return source.length > 2;
};
