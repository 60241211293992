import { useState } from "react";
import { ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import NavigationControl from "../component/NavigationControl";
import ValidatedTextField from "./ValidateTextField";
import { InputTheme } from "../../common/utils/ThemeUtils";
import {
  isEmpty,
  isNotEmpty,
  isValidAccountName,
  isValidEmail,
} from "../utils/ValidationUtils";
import {
  showDialog,
  DialogScope,
} from "../../common/dialog/GenericScopeDialog";
import { ReactComponent as ProfileImage } from "../assets/profile_image.svg";
import styles from "../style/registration.module.css";

const validateNameRule = (value) => {
  return isEmpty(value) || !isValidAccountName(value);
};

const validateEmailRule = (value) => {
  if (isNotEmpty(value)) {
    return !isValidEmail(value);
  } else {
    return false;
  }
};

const ProfileForm = (props) => {
  const [name, setName] = useState(props.name);
  const [email, setEmail] = useState(props.email);
  const [phone, setPhone] = useState(props.phone);

  return (
    <div className={styles.form}>
      <div className={styles.formGroup}>
        <div style={{ padding: "11vh 0" }} className={styles.inputGroup}>
          <ThemeProvider theme={InputTheme}>
            <ValidatedTextField
              label="Name"
              placeholder="Enter the account name"
              value={name}
              errorMessage="Provide valid name before proceeding"
              onChange={(value) => {
                setName(value);
              }}
              validateRule={validateNameRule}
            />
            <ValidatedTextField
              label="Email"
              placeholder="Enter the account email address"
              value={email}
              errorMessage="Provide valid email address before proceeding"
              onChange={(value) => {
                setEmail(value);
              }}
              validateRule={validateEmailRule}
            />
            <TextField
              label="Phone"
              placeholder="Enter the account phone number"
              defaultValue={phone}
              size="small"
              fullWidth
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
          </ThemeProvider>
        </div>
        <div style={{ backgroundColor: "white" }} className={styles.inputGroup}>
          <ProfileImage style={{ alignSelf: "flex-end" }} width={270} />
        </div>
      </div>
      <NavigationControl
        onClick={(event) => {
          if (validateNameRule(name) || validateEmailRule(email)) {
            event.preventDefault();
            showDialog(
              "Warning",
              `Please ensure that the account name is accurately entered; the account
              email is optional but should be a genuine email address to which we can
              send account-related notifications.`,
              DialogScope.ERROR
            );
          } else {
            props.onClick({ name, email, phone });
          }
        }}
      />
    </div>
  );
};

export default ProfileForm;
