import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ListItemView from "./ListItemView";
import { Path as RegistrationPath } from "../../../registration/utils/PathUtils";
import styles from "../../style/pricing.module.css";

const contentStyle = {
  content: {
    top: "5vh",
    bottom: "5vh",
    left: "32vw",
    right: "32vw",
    border: "2px solid #50C878",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "3vw",
  },
};

const closeStyle = {
  position: "absolute",
  right: "1vh",
  top: "1vh",
};

const FreePlanView = () => {
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <NavLink
        to={"#"}
        className={styles.messageLabel}
        onClick={() => {
          setVisible(true);
        }}
      >
        free option
      </NavLink>
      <Modal isOpen={visible} style={contentStyle} onRequestClose={onClose}>
        <IconButton style={closeStyle} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div>
          <div
            className={styles.planContent}
            style={{
              width: "auto",
              height: "auto",
            }}
          >
            <label
              className={styles.planLabel}
              style={{
                backgroundColor: "#50C878",
                width: "10vw",
              }}
            >
              Free
            </label>
            <div
              style={{
                marginTop: "2vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  color: "#0D6986",
                  fontSize: "1vw",
                  fontWeight: "bold",
                }}
              >
                Our Free Plan is your starting point
              </label>
            </div>
            <label className={styles.planMessage}>
              Designed for those who want to explore and review possibilities at
              no cost, it includes essential features to support your initial
              journey. When you're ready to unlock the full potential of our
              platform and access a broader set of features, we offer various
              upgrade options to suit your evolving needs.
            </label>
            <label style={{ color: "#0D6986", fontSize: "0.9vw" }}>
              If our Free Plan fits your needs feel free to use without time
              limits.
            </label>
            <NavLink
              to={RegistrationPath.PROFILE}
              className={styles.startedButton}
            >
              Get Started for Free
            </NavLink>
            <div
              style={{
                marginTop: "3vh",
                marginBottom: "3vh",
                border: "0.5px solid #0D6986",
              }}
            />
            <label
              style={{
                color: "#0D6986",
                fontSize: "1.1vw",
                fontWeight: "bold",
                marginBottom: "1vh",
              }}
            >
              Join now to reserve a free option plan
            </label>
            <ListItemView color={"#50C878"} info={undefined}>
              up to 3 users
            </ListItemView>
            <ListItemView color={"#50C878"} info={undefined}>
              restricted access
            </ListItemView>
            <ListItemView color={"#50C878"} info={undefined}>
              offering management
            </ListItemView>
            <ListItemView color={"#50C878"} info={undefined}>
              pricing management
            </ListItemView>
            <ListItemView color={"#50C878"} info={undefined}>
              availability management
            </ListItemView>
            <ListItemView color={"#50C878"} info={undefined}>
              checklist fulfilment
            </ListItemView>
            <ListItemView color={"#50C878"} info={undefined}>
              ordering fulfilment
            </ListItemView>
            <ListItemView color={"#50C878"} info={undefined}>
              partnership management
            </ListItemView>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FreePlanView;
