import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EmptyPageView from "../component/EmptyPageView";
import {
  addContact,
  addContacts,
  excludeContact,
} from "../redux/partnershipSlice";
import {
  fetchContacts,
  enableContactRequest,
  suspendContactRequest,
} from "../client/AxiosClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { Transition } from "../utils/EffectUtils";
import { phraseMatches } from "../utils/SearchUtils";
import styles from "../style/contact.module.css";

const AccountControlButton = (props) => {
  const [active, setActive] = useState(false);

  const onDialogClose = () => {
    setActive(false);
  };

  return (
    <div>
      <NavLink to="#" className={styles.button} onClick={() => setActive(true)}>
        {props.label}
      </NavLink>
      <Dialog
        open={active}
        TransitionComponent={Transition}
        keepMounted
        onClose={onDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDialogClose}>Dismiss</Button>
          <Button
            onClick={() => {
              onDialogClose();
              props.onClick();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ContactPage = () => {
  const dispatch = useDispatch();
  const { phrase } = useSelector((state) => state.search);
  const { contacts } = useSelector((state) => state.partnership);

  useEffect(() => {
    if (!contacts) {
      showLoading();
      fetchContacts()
        .then((data) => dispatch(addContacts(data)))
        .finally(hideLoading);
    }
  });

  if (contacts) {
    const actualContacts = contacts.filter((contact) => {
      if (phrase !== undefined) {
        return phraseMatches(contact.account.name, phrase);
      } else {
        return true;
      }
    });
    if (actualContacts.length > 0) {
      return (
        <div className={styles.content}>
          {actualContacts.map((contact) => {
            return (
              <div
                className={styles.contact}
                style={{ opacity: contact.editable ? 1 : 0.5 }}
                key={contact.uuid}
              >
                <div
                  className={styles.logo}
                  style={{ opacity: contact.active ? 1 : 0.7 }}
                >
                  <img
                    className={styles.image}
                    src={contact.account.logo}
                    alt={""}
                  />
                </div>
                <div
                  className={styles.label}
                  style={{ opacity: contact.active ? 1 : 0.7 }}
                >
                  <label className={styles.name}>{contact.account.name}</label>
                  {contact.active ? (
                    <label className={styles.activeStatus}>active</label>
                  ) : (
                    <label className={styles.suspendedStatus}>suspended</label>
                  )}
                </div>
                <div className={styles.control}>
                  {contact.active ? (
                    <AccountControlButton
                      label="suspend"
                      title={`Suspend ${contact?.account?.name}'s connection`}
                      message={`You're about to suspend connection with contact "${contact?.account?.name}" contact.
                        You can enable the connection later if necessary. Do you want to continue?`}
                      onClick={() => {
                        if (contact.editable) {
                          showLoading();
                          suspendContactRequest(contact.uuid)
                            .then(() => {
                              dispatch(excludeContact(contact.uuid));
                              dispatch(
                                addContact({
                                  uuid: contact.uuid,
                                  active: false,
                                  editable: true,
                                  account: contact.account,
                                })
                              );
                            })
                            .finally(hideLoading);
                        }
                      }}
                    />
                  ) : (
                    <AccountControlButton
                      label="enable"
                      title={`Enable ${contact?.account?.name}'s connection`}
                      message={`You're about to enable "${contact?.account?.name}" contact. This action will make
                        the connection active. If necessary can suspend later. Do you want to continue?`}
                      onClick={() => {
                        if (contact.editable) {
                          showLoading();
                          enableContactRequest(contact.uuid)
                            .then(() => {
                              dispatch(excludeContact(contact.uuid));
                              dispatch(
                                addContact({
                                  uuid: contact.uuid,
                                  active: true,
                                  editable: true,
                                  account: contact.account,
                                })
                              );
                            })
                            .finally(hideLoading);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <EmptyPageView
          message={`It looks like you don’t have any contacts at the moment.
            Explore and connect with new accounts to get started.`}
        />
      );
    }
  }
};

export default ContactPage;
