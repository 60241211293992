import { NavLink } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import styles from "../style/option.module.css";

const LogoutOption = () => {
  return (
    <NavLink to={"#"} className={`${styles.option} ${styles.inactive}`}>
      <LogoutIcon />
      log out
    </NavLink>
  );
};

export default LogoutOption;
