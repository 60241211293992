import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IconButton from "@mui/material/IconButton";
import EmptyPageView from "../component/EmptyPageView";
import {
  addRequests,
  excludeRequest,
  addContact,
} from "../redux/partnershipSlice";
import {
  fetchRequests,
  enableContactRequest,
  suspendContactRequest,
} from "../client/AxiosClient";
import {
  hideLoading,
  showLoading,
} from "../../common/dialog/ThreeCirclesLoading";
import { Transition } from "../utils/EffectUtils";
import { phraseMatches } from "../utils/SearchUtils";
import styles from "../style/request.module.css";

const RequestControlButton = (props) => {
  const [active, setActive] = useState(false);

  const onClose = () => {
    setActive(false);
  };

  return (
    <div>
      <IconButton onClick={() => setActive(true)}>{props.children}</IconButton>
      <Dialog
        open={active}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Dismiss</Button>
          <Button
            onClick={() => {
              onClose();
              props.onClick();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const RequestPage = () => {
  const dispatch = useDispatch();
  const { requests } = useSelector((state) => state.partnership);
  const { phrase } = useSelector((state) => state.search);

  useEffect(() => {
    if (!requests) {
      showLoading();
      fetchRequests()
        .then((data) => dispatch(addRequests(data)))
        .finally(hideLoading);
    }
  });

  if (requests) {
    const actualRequests = requests.filter((request) => {
      if (phrase !== undefined) {
        return phraseMatches(request.account.name, phrase);
      } else {
        return true;
      }
    });
    if (actualRequests.length > 0) {
      return (
        <div className={styles.content}>
          {requests
            .filter((request) => {
              if (phrase !== undefined) {
                return phraseMatches(request.account.name, phrase);
              } else {
                return true;
              }
            })
            .map((request) => {
              return (
                <div className={styles.request} key={request.uuid}>
                  <div className={styles.logo}>
                    <img
                      className={styles.image}
                      src={request.account.logo}
                      alt={""}
                    />
                  </div>
                  <label className={styles.name}>{request.account.name}</label>
                  {request.editable ? (
                    <div className={styles.control}>
                      <RequestControlButton
                        title="Confirm connection acceptance"
                        message={`You're about to accept a contact request from "${request?.account?.name}". You can suspend the connection later if necessary. Do you want to proceed?`}
                        onClick={() => {
                          showLoading();
                          enableContactRequest(request.uuid)
                            .then(() => {
                              dispatch(excludeRequest(request.uuid));
                              dispatch(
                                addContact({
                                  uuid: request.uuid,
                                  active: true,
                                  editable: true,
                                  account: request.account,
                                })
                              );
                            })
                            .finally(hideLoading);
                        }}
                      >
                        <CheckCircleOutlineIcon htmlColor="green" />
                      </RequestControlButton>
                      <RequestControlButton
                        title="Decline connection request"
                        message={`You're about to reject "${request?.account?.name}" contact request. You can enable the connection later if necessary. Do you want to proceed?`}
                        onClick={() => {
                          showLoading();
                          suspendContactRequest(request.uuid)
                            .then(() => {
                              dispatch(excludeRequest(request.uuid));
                              dispatch(
                                addContact({
                                  uuid: request.uuid,
                                  active: false,
                                  editable: true,
                                  account: request.account,
                                })
                              );
                            })
                            .finally(hideLoading);
                        }}
                      >
                        <HighlightOffIcon htmlColor="red" />
                      </RequestControlButton>
                    </div>
                  ) : (
                    <div className={styles.control}>
                      <label className={styles.pendingStatus}>pending</label>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      );
    } else {
      return (
        <EmptyPageView
          message={`It looks like you don’t have any active contact requests
        right now. Explore and connect with new accounts to get started.`}
        />
      );
    }
  }
};

export default RequestPage;
