import React from "react";
import HeaderView from "../component/HeaderView";
import FooterView from "../component/FooterView";
import FreePlanView from "../component/pricing/FreePlanView";
import BasicPlanView from "../component/pricing/BasicPlanView";
import BusinessPlanView from "../component/pricing/BusinessPlanView";
import EnterprisePlanView from "../component/pricing/EnterprisePlanView";
import styles from "../style/pricing.module.css";

const PricingPage = () => {
  return (
    <div>
      <HeaderView />
      <div className={styles.content}>
        <label className={styles.headerLabel}>
          Supercharge your business. Chose pricing plan.
        </label>
        <label style={{ marginTop: "1vh" }} className={styles.messageLabel}>
          Get started to unlock exclusive benefits.
        </label>
        <div style={{ display: "flex" }}>
          <label className={styles.messageLabel}>
            Earn credit points and unlock discounts on your first purchase, or
            enjoy the &nbsp;
          </label>
          <FreePlanView />
          <label className={styles.messageLabel}>.</label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85vw",
            marginTop: "10vh",
          }}
        >
          <BasicPlanView />
          <BusinessPlanView />
          <EnterprisePlanView />
        </div>
      </div>
      <FooterView />
    </div>
  );
};

export default PricingPage;
