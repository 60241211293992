import React from "react";
import { NavLink } from "react-router-dom";
import { Grid } from "@mui/material";
import { ReactComponent as FacebookIcon } from "../assets/facebook-icon.svg";
import { ReactComponent as LinkedInIcon } from "../assets/linkedin-icon.svg";
import { ReactComponent as EmailIcon } from "../assets/email-icon.svg";
import { Path as RegistrationPath } from "../../registration/utils/PathUtils";
import styles from "../style/footer.module.css";

const FooterView = () => {
  return (
    <div className={styles.content}>
      <label style={{ fontSize: "3vh" }} className={styles.label}>
        Ready to get started?
      </label>
      <label style={{ fontSize: "2vh" }} className={styles.label}>
        Our services are available to you absolutely free of charge.
      </label>
      <div
        style={{
          width: "32vw",
          paddingBottom: "1vh",
          borderBottom: "1px solid #0D6986",
        }}
      >
        <NavLink
          to={RegistrationPath.PROFILE}
          className={styles.label}
          style={{ fontSize: "2.5vh", color: "#5EA6C5" }}
        >
          Type company name and start journey
        </NavLink>
      </div>
      <Grid container columns={20}>
        <Grid item xs={12} className={styles.gridItem}>
          <div className={styles.gridItemContent}>
            <label style={{ fontSize: "3vh" }} className={styles.label}>
              Shadwork
            </label>
            <label style={{ fontSize: "2.3vh" }} className={styles.label}>
              Unlock the power of Digital Transformation.
            </label>
          </div>
        </Grid>
        <Grid item xs={4} className={styles.gridItem}>
          <div className={styles.gridItemContent}>
            <NavLink
              to={"/terms"}
              style={{ fontSize: "2.3vh" }}
              className={styles.label}
            >
              Terms and Conditions
            </NavLink>
            <NavLink
              to={"/privacy"}
              style={{ fontSize: "2.3vh" }}
              className={styles.label}
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to={"/cookie"}
              style={{ fontSize: "2.3vh" }}
              className={styles.label}
            >
              Cookie Policy
            </NavLink>
          </div>
        </Grid>
        <Grid item xs={4} className={styles.gridItem}>
          <div style={{ alignItems: "end" }} className={styles.gridItemContent}>
            <label style={{ fontSize: "2.6vh" }} className={styles.label}>
              Let’s connect!
            </label>
            <div className={styles.socialMediaContent}>
              <NavLink to={"#"}>
                <FacebookIcon width={30} />
              </NavLink>
              <NavLink to={"#"}>
                <LinkedInIcon width={30} />
              </NavLink>
              <NavLink
                to={
                  "mailto:support@shadwork.com?subject=Request for Assistance"
                }
              >
                <EmailIcon width={30} />
              </NavLink>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FooterView;
