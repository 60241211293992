import { NavLink } from "react-router-dom";
import ListItemView from "./ListItemView";
import { Path as RegistrationPath } from "../../../registration/utils/PathUtils";
import styles from "../../style/pricing.module.css";

const unlimitedUserNumber = `
  Offers the flexibility of accommodating an unlimited number of users. You can 
  grow your team without worrying about user limitations, ensuring that everyone 
  can access and benefit from our services
`;
const basedRoleConfiguration = `
  Empowers you to efficiently manage your team by assigning roles with specific 
  permissions from a set of predefined options, ensuring efficient and secure 
  access control for your team.
`;

const exclusiveAddons = `
  Your account gains access to a range of premium add-ons that can be enabled at 
  your convenience. This ensures you have the flexibility to enhance your experience 
  with additional, exclusive features as needed.
`;

const extendedSearching = `
  Enables you to monitor your account with ease. With this feature, you can keep a 
  close eye on your account activity, track usage, and gain valuable insights to
  make informed decisions and ensure your account operates at its best.
`;

const activityMonitoring = `
  Provides you with an array of refined search criteria and filters to pinpoint the 
  exact information you need when configured by the vendor. With this feature, you 
  can streamline your search process, discover more precise results, and access 
  information tailored to your specific needs.
`;

const resourcesCategorisation = `
  Empowers you to efficiently organize your offerings within established categories, 
  making it simpler for buyers to discover and browse your products or services with ease.
`;

const extendedChecklistFulfilment = `
  Takes your checklist management to the next level. In addition to the basic 
  features, this option allows you to effortlessly gather products or services from 
  multiple vendors and seamlessly place orders with a single click. It streamlines 
  the procurement process, making it more efficient and convenient for your purchasing 
  needs.
`;

const unlimitedImageStorage = `
  You can securely store and manage your images without worrying about storage 
  limitations, ensuring that you have ample space for all your visual assets.
`;

const documentStorage = `
  Provides you with comprehensive control over your documents. You can easily create, 
  update, and securely share documents with your partners. Plus, when the time comes 
  to remove them, you have the option to permanently delete files, ensuring a 
  well-organized and secure document management process.
`;

const extendedInsightsDashboard = `
  Offers an in-depth view of your data and performance. With this option, you can 
  access advanced analytics and gain valuable insights into key metrics, helping 
  you make informed decisions, track progress, and drive success with a deeper 
  understanding of your information.
`;

const BusinessPlanView = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#5EA6C5" }} className={styles.panel} />
      <div
        className={styles.planContent}
        style={{
          borderLeft: "2px solid #5EA6C5",
          borderRight: "2px solid #5EA6C5",
          borderBottom: "2px solid #5EA6C5",
        }}
      >
        <label
          className={styles.planLabel}
          style={{
            backgroundColor: "#5EA6C5",
            width: "9vw",
          }}
        >
          Business
        </label>
        <div style={{ marginTop: "2vh" }}>
          <label className={styles.planPrice}>€845</label>
          <label className={styles.planPeriod}>/MONTHLY</label>
        </div>
        <label className={styles.planMessage}>
          Perfect for businesses seeking a comprehensive B2B marketplace
          solution. Access to all the essential and advanced features with the
          flexibility to enable or suspend add-ons as needed.
        </label>
        <NavLink to={RegistrationPath.PROFILE} className={styles.startedButton}>
          Get Started for Free
        </NavLink>
        <div
          style={{
            marginTop: "3vh",
            marginBottom: "3vh",
            border: "0.5px solid #0D6986",
          }}
        />
        <ListItemView color={"#5EA6C5"} info={unlimitedUserNumber}>
          unlimited number of users
        </ListItemView>
        <ListItemView color={"#5EA6C5"} info={basedRoleConfiguration}>
          based role access control
        </ListItemView>
        <ListItemView color={"#5EA6C5"} info={exclusiveAddons}>
          exclusive add-ons access
        </ListItemView>
        <ListItemView color={"#5EA6C5"} info={extendedSearching}>
          account activity monitoring
        </ListItemView>
        <ListItemView color={"#5EA6C5"} info={activityMonitoring}>
          expanded search discovery
        </ListItemView>
        <ListItemView color={"#5EA6C5"} info={resourcesCategorisation}>
          product and service categorisation
        </ListItemView>
        <ListItemView color={"#5EA6C5"} info={extendedChecklistFulfilment}>
          extended checklist fulfilment
        </ListItemView>
        <ListItemView color={"#5EA6C5"} info={unlimitedImageStorage}>
          unlimited image storage
        </ListItemView>
        <ListItemView color={"#5EA6C5"} info={documentStorage}>
          document repository: up to 10Gb
        </ListItemView>
        <ListItemView color={"#5EA6C5"} info={extendedInsightsDashboard}>
          extended dashboard insights
        </ListItemView>
      </div>
    </div>
  );
};

export default BusinessPlanView;
