import React from "react";

const EmptyPageView = (props) => {
  return (
    <div
      style={{
        flex: 1,
        paddingLeft: "10vw",
        paddingRight: "10vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <label
        style={{
          fontStyle: "italic",
          fontSize: "1.6vw",
          color: "#0D6986",
          textAlign: "center",
        }}
      >
        {props.message}
      </label>
    </div>
  );
};

export default EmptyPageView;
