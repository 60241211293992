import moment from "moment";
import axios from "axios";
import Cookies from "js-cookie";
import { CookieName } from "../utils/CookieUtils";

const localToken = "token";

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_BASE_URL}`,
  timeout: 10 * 1000,
});

export const clientAuthenticationUrl = `${process.env.REACT_APP_AUTH_BASE_URL}/realms/${process.env.REACT_APP_AUTH_REALM}/protocol/openid-connect/auth?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASE_URL}/authorise&response_mode=fragment&response_type=code&scope=openid`;

export const parseAuthToken = (data) => {
  const token = {
    access_token: data.access_token,
    expires_at: moment.now() + (data.expires_in - 10) * 1000,
    refresh_token: data.refresh_token,
    refresh_expires_at: moment.now() + (data.refresh_expires_in - 10) * 1000,
  };
  localStorage.setItem(localToken, JSON.stringify(token));
  return token;
};

export const authenticate = async (code) => {
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("client_id", `${process.env.REACT_APP_AUTH_CLIENT_ID}`);
  params.append("code", code);
  params.append("redirect_uri", `${process.env.REACT_APP_BASE_URL}/authorise`);
  const response = await axiosClient.post(
    `realms/shadwork/protocol/openid-connect/token`,
    params
  );
  parseAuthToken(response.data);
};

export const resetToken = () => {
  localStorage.removeItem(localToken);
};

export const authorisationToken = () => {
  const token = JSON.parse(localStorage.getItem(localToken));
  return new Promise((resolve, _) => {
    if (token) {
      if (moment(token.expires_at).isAfter(moment.now())) {
        resolve(token.access_token);
      } else if (moment(token.refresh_expires_at).isAfter(moment.now())) {
        const params = new URLSearchParams();
        params.append("grant_type", "refresh_token");
        params.append("client_id", `${process.env.REACT_APP_AUTH_CLIENT_ID}`);
        params.append("refresh_token", token.refresh_token);
        return axiosClient
          .post("realms/shadwork/protocol/openid-connect/token", params)
          .then((response) => {
            return parseAuthToken(response.data);
          });
      } else {
        // authorisation token has been expired, redirect to login page
        Cookies.set(CookieName.DOCUMENT_LOCATION, document.location.pathname);
        document.location = clientAuthenticationUrl;
      }
    } else {
      // authorisation token is missing, redirect to login page
      Cookies.set(CookieName.DOCUMENT_LOCATION, document.location.pathname);
      document.location = clientAuthenticationUrl;
    }
  });
};
