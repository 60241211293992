import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "partnershipSearch",
  initialState: {
    phrase: undefined,
  },
  reducers: {
    updateSearchPhrase(state, action) {
      state.phrase = action.payload;
    },
    resetSearchPhrase(state) {
      state.phrase = undefined;
    },
  },
});

export const { updateSearchPhrase, resetSearchPhrase } = searchSlice.actions;

export default searchSlice.reducer;
