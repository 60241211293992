import { NavLink } from "react-router-dom";
import ListItemView from "./ListItemView";
import { Path as RegistrationPath } from "../../../registration/utils/PathUtils";
import styles from "../../style/pricing.module.css";

const maximumUserNumber = `
  Registered users encompass account administrators and employees who hold 
  responsibilities for managing account resources or engaging in B2B transactions.
`;

const restrictedAccessInfo = `
  Access is restricted to that users who have successfully registered and verified 
  their accounts. Only self-authorised accounts can view or access your resources.
  This added layer of security helps protect our account and account data, creating 
  a safer and more trusted environment for all participants.
`;

const offeringManagement = `
  Empowers you to efficiently handle and oversee your entire range of products, 
  group of products, and services. It streamlines the process of creating, updating, 
  and optimizing your offerings, ensuring that you can deliver the best value to 
  your customers.
`;

const attributesManagement = `
  Empowers you to efficiently manage the pricing and availability of your offerings. 
  You have the flexibility to customize pricing and availability settings on a 
  per-client level, assuring your marketing strategy and providing a tailored 
  experience for your clients.
`;

const checklistFulfilment = `
  Empowers you to efficiently manage and track the completion of items on your 
  checklist. You can easily visualize, update, and confirm the fulfillment of 
  selected products or services, streamlining the process and ensuring that your 
  orders are accurate and on schedule.
`;

const orderingFulfilment = `
  Order fulfillment management feature streamlines the process of completing orders, 
  ensuring smooth and efficient order delivery. With this tool, you can manage the 
  fulfillment of customer orders, track progress, and coordinate delivery, making sure 
  that orders are processed and delivered accurately and on time.
`;

const userProfileManagement = `
  Enables you to have complete control over user accounts, allowing you to perform 
  actions such as creating, suspending, activating, and removing users as needed.
`;

const clientProfileManagement = `
  Enables you to manage client relationship effectively. You can temporarily 'suspend' 
  a client to pause their activities within you when necessary and 'activate' them 
  again when you're ready to resume. This gives you full control over your client 
  relationships without any permanent actions.
`;

const imageStorage = `
   Image storage repository provides you with the capacity to securely store your 
   images with up to 5 GB of storage space. This means you can conveniently upload, 
   organize, access your visual content, and setup to your offerings, ensuring that 
   you have ample storage for all your image assets.
`;

const insightsDashboard = `
  Offers you immediate access to a collection of predefined dashboards specifically 
  tailored to sales and purchase data. These dashboards are designed to provide 
  you with valuable insights into your transactions, helping you make informed 
  decisions and track performance effortlessly
`;

const BasicPlanView = () => {
  return (
    <div>
      <div style={{ backgroundColor: "#BFF9E4" }} className={styles.panel} />
      <div
        className={styles.planContent}
        style={{
          borderLeft: "2px solid #BFF9E4",
          borderRight: "2px solid #BFF9E4",
          borderBottom: "2px solid #BFF9E4",
        }}
      >
        <label
          className={styles.planLabel}
          style={{
            backgroundColor: "#BFF9E4",
            width: "6vw",
          }}
        >
          Basic
        </label>
        <div style={{ marginTop: "2vh" }}>
          <label className={styles.planPrice}>€165</label>
          <label className={styles.planPeriod}>/MONTHLY</label>
        </div>
        <label className={styles.planMessage}>
          Is your entry point to digital B2B solutions. Ideal for businesses
          ready to take their first steps in the world of B2B transactions.
        </label>
        <NavLink to={RegistrationPath.PROFILE} className={styles.startedButton}>
          Get Started for Free
        </NavLink>
        <div
          style={{
            marginTop: "3vh",
            marginBottom: "3vh",
            border: "0.5px solid #0D6986",
          }}
        />
        <ListItemView color={"#BFF9E4"} info={maximumUserNumber}>
          up to 10 managed users
        </ListItemView>
        <ListItemView color={"#BFF9E4"} info={restrictedAccessInfo}>
          restricted access
        </ListItemView>
        <ListItemView color={"#BFF9E4"} info={offeringManagement}>
          offering management
        </ListItemView>
        <ListItemView color={"#BFF9E4"} info={attributesManagement}>
          pricing and availability management
        </ListItemView>
        <ListItemView color={"#BFF9E4"} info={checklistFulfilment}>
          checklist fulfilment
        </ListItemView>
        <ListItemView color={"#BFF9E4"} info={orderingFulfilment}>
          ordering fulfilment
        </ListItemView>
        <ListItemView color={"#BFF9E4"} info={userProfileManagement}>
          user profile management
        </ListItemView>
        <ListItemView color={"#BFF9E4"} info={clientProfileManagement}>
          partnership management
        </ListItemView>
        <ListItemView color={"#BFF9E4"} info={imageStorage}>
          image repository: up to 5Gb
        </ListItemView>
        <ListItemView color={"#BFF9E4"} info={insightsDashboard}>
          purchase and sales insights
        </ListItemView>
      </div>
    </div>
  );
};

export default BasicPlanView;
