import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import NavigationStage from "../component/NavigationStage";
import ProfileForm from "../component/ProfileForm";
import AddressForm from "../component/AddressForm";
import AdministratorForm from "../component/AdministratorForm";
import SubmitForm from "../component/SubmitForm";
import { Path } from "../utils/PathUtils";
import styles from "../style/registration.module.css";

const RegistrationPage = () => {
  const [profile, setProfile] = useState({});
  const [address, setAddress] = useState({});
  const [admin, setAdmin] = useState({});

  const location = useLocation();

  const renderForm = () => {
    if (location.pathname === Path.PROFILE) {
      return <ProfileForm {...profile} onClick={setProfile} />;
    } else if (location.pathname === Path.ADDRESS) {
      return <AddressForm {...address} onClick={setAddress} />;
    } else if (location.pathname === Path.ADMIN) {
      return <AdministratorForm email={profile.email} {...admin} onClick={setAdmin} />;
    } else if (location.pathname === Path.SUBMIT) {
      return <SubmitForm {...profile} {...address} {...admin} />;
    } else {
      return <Navigate to={Path.PROFILE} />;
    }
  };

  return (
    <div className={styles.body}>
      <div className={styles.dialog}>
        <NavigationStage />
        {renderForm()}
      </div>
    </div>
  );
};

export default RegistrationPage;
