import { useRef, useState } from "react";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../style/header.module.css";

const SearchView = (props) => {
  const [phrase, setPhrase] = useState("");
  const searchInputRef = useRef();

  return (
    <div className={styles.search}>
      <IconButton
        size="small"
        onClick={() => phrase?.length > 0 && props.onSearch(phrase)}
      >
        <SearchIcon htmlColor="#707070" />
      </IconButton>
      <input
        value={phrase}
        ref={searchInputRef}
        placeholder="Search"
        onChange={(event) => {
          const value = event.target.value;
          setPhrase(value);
          if (props.onSearchTyping) {
            props.onSearchTyping(value);
          }
        }}
        style={{ all: "unset", fontStyle: "italic", flex: 1 }}
      />
      {phrase && (
        <IconButton
          size="small"
          onClick={() => {
            setPhrase("");
            searchInputRef.current.value = "";
            if (props.onSearchCancel) {
              props.onSearchCancel();
            }
          }}
        >
          <CloseIcon htmlColor="#707070" />
        </IconButton>
      )}
    </div>
  );
};

const SearchableHeaderView = (props) => {
  return (
    <div className={styles.header}>
      <Grid container columns={3} sx={{ marginBottom: "1vh" }}>
        <Grid item xs={1} sx={{ display: "flex", alignItems: "flex-end" }}>
          {props?.label}
        </Grid>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
          {props.onSearch && (
            <SearchView
              onSearch={props.onSearch}
              onSearchTyping={props.onTyping}
              onSearchCancel={props.onCancel}
            />
          )}
        </Grid>
        <Grid item xs={1} className={styles.action}>
          {props.children}
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchableHeaderView;
