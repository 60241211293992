import { useState } from "react";
import { configureStore, createSlice } from "@reduxjs/toolkit";
import Modal from "react-modal";
import { ThreeCircles } from "react-loader-spinner";

const slice = createSlice({
  name: "threeCircleLoadingState",
  initialState: false,
  reducers: {
    showLoading(state) {
      state = true;
      return state;
    },
    hideLoading(state) {
      state = false;
      return state;
    },
  },
});

const store = configureStore({
  reducer: {
    threeCircleLoadingState: slice.reducer,
  },
});

const showLoading = () => {
  store.dispatch(slice.actions.showLoading());
};

const hideLoading = () => {
  store.dispatch(slice.actions.hideLoading());
};

const contentStyle = {
  content: {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  overlay: {
    zIndex: 10,
  },
};

const ThreeCirclesLoading = () => {
  const [visible, setVisible] = useState(
    store.getState().threeCircleLoadingState
  );

  store.subscribe(() => {
    setVisible(store.getState().threeCircleLoadingState);
  });

  return (
    <Modal isOpen={visible} style={contentStyle}>
      <ThreeCircles
        height="15vh"
        width="15vh"
        innerCircleColor="#5EA6C5"
        middleCircleColor="#7FC6E6"
        outerCircleColor="#0D6986"
        wrapperStyle={{}}
        visible={visible}
        ariaLabel="three-circles-rotating"
      />
    </Modal>
  );
};

export { showLoading, hideLoading };

export default ThreeCirclesLoading;
