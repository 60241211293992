import { useState } from "react";
import TextField from "@mui/material/TextField";

const ValidatedTextField = (props) => {
  const [error, setError] = useState();

  return (
    <TextField
      error={error !== undefined}
      label={error ? error : `${props.label}*`}
      placeholder={props.placeholder}
      defaultValue={props.value}
      size="small"
      fullWidth
      onChange={(event) => {
        props.onChange(event.target.value);
      }}
      onBlur={(event) => {
        if (props.validateRule && props.validateRule(event.target.value)) {
          setError(props.errorMessage);
        } else {
          setError(undefined);
        }
      }}
    />
  );
};

export default ValidatedTextField;
