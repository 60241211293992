import { useLocation } from "react-router-dom";
import { Path } from "../utils/PathUtils";
import styles from "../style/registration.module.css";

const NavigationState = () => {
  const location = useLocation();

  return (
    <div className={styles.navigationGroup}>
      <div className={styles.stage}>
        <div className={styles.bulletGroup}>
          <div style={{ backgroundColor: "white" }} className={styles.line} />
          <div
            style={{ backgroundColor: "#0d6986" }}
            className={styles.bullet}
          />
          <div
            style={{
              backgroundColor:
                location.pathname !== Path.PROFILE ? "#0d6986" : "#707070",
            }}
            className={styles.line}
          />
        </div>
        <label style={{ color: "#0d6986", fontWeight: "bold" }}>account</label>
      </div>
      <div
        style={{
          backgroundColor:
            location.pathname !== Path.PROFILE ? "#0d6986" : "#707070",
        }}
        className={styles.line}
      />
      <div className={styles.stage}>
        <div className={styles.bulletGroup}>
          <div
            style={{
              backgroundColor:
                location.pathname !== Path.PROFILE ? "#0d6986" : "#707070",
            }}
            className={styles.line}
          />
          <div
            style={{
              backgroundColor:
                location.pathname !== Path.PROFILE ? "#0d6986" : "#707070",
            }}
            className={styles.bullet}
          />
          <div
            style={{
              backgroundColor:
                location.pathname !== Path.PROFILE &&
                location.pathname !== Path.ADDRESS
                  ? "#0d6986"
                  : "#707070",
            }}
            className={styles.line}
          />
        </div>
        <label
          style={{
            color: location.pathname !== Path.PROFILE ? "#0d6986" : "#707070",
            fontWeight: "bold",
          }}
        >
          address
        </label>
      </div>

      <div
        style={{
          backgroundColor:
            location.pathname !== Path.PROFILE &&
            location.pathname !== Path.ADDRESS
              ? "#0d6986"
              : "#707070",
        }}
        className={styles.line}
      />

      <div className={styles.stage}>
        <div className={styles.bulletGroup}>
          <div
            style={{
              backgroundColor:
                location.pathname === Path.ADMIN ||
                location.pathname === Path.SUBMIT
                  ? "#0d6986"
                  : "#707070",
            }}
            className={styles.line}
          />
          <div
            style={{
              backgroundColor:
                location.pathname === Path.ADMIN ||
                location.pathname === Path.SUBMIT
                  ? "#0d6986"
                  : "#707070",
            }}
            className={styles.bullet}
          />
          <div
            style={{
              backgroundColor:
                location.pathname === Path.SUBMIT ? "#0d6986" : "#707070",
            }}
            className={styles.line}
          />
        </div>
        <label
          style={{
            color:
              location.pathname !== Path.PROFILE &&
              location.pathname !== Path.ADDRESS
                ? "#0d6986"
                : "#707070",
            fontWeight: "bold",
          }}
        >
          administrator
        </label>
      </div>

      <div
        style={{
          backgroundColor:
            location.pathname === Path.SUBMIT ? "#0d6986" : "#707070",
        }}
        className={styles.line}
      />
      <div className={styles.stage}>
        <div className={styles.bulletGroup}>
          <div
            style={{
              backgroundColor:
                location.pathname === Path.SUBMIT ? "#0d6986" : "#707070",
            }}
            className={styles.line}
          />
          <div
            style={{
              backgroundColor:
                location.pathname === Path.SUBMIT ? "#0d6986" : "#707070",
            }}
            className={styles.bullet}
          />
          <div style={{ backgroundColor: "white" }} className={styles.line} />
        </div>
        <label
          style={{
            color: location.pathname === Path.SUBMIT ? "#0d6986" : "#707070",
            fontWeight: "bold",
          }}
        >
          submit
        </label>
      </div>
    </div>
  );
};

export default NavigationState;
