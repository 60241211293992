import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";

const TooltipView = (props) => {
  if (props.info) {
    return (
      <Tooltip arrow title={props.info} placement="right">
        <InfoOutlinedIcon htmlColor={"#0D6986"} fontSize={"small"} />
      </Tooltip>
    );
  }
};

const ListItemView = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0.4vh 0",
      }}
    >
      <div
        style={{
          width: "1vw",
          height: "1vw",
          borderRadius: "1vw",
          backgroundColor: props.color,
        }}
      />
      <label
        style={{
          flex: 1,
          marginLeft: "1vw",
          color: "#0D6986",
          fontSize: "1vw",
        }}
      >
        {props.children}
      </label>
      <TooltipView info={props.info} />
    </div>
  );
};

export default ListItemView;
