import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { showCallableDialog, DialogScope } from "../dialog/GenericScopeDialog";

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    showCallableDialog(
      "Oops!",
      "The page you are looking for does not exist.",
      DialogScope.ERROR
    ).then(() => navigate(-1));
  });
};

export default NotFoundPage;
