import axiosClient from "../../common/client/AxiosClient";
import { executeGetJsonStreamRequest } from "../../common/client/AxiosClient";
import { authorisationToken } from "../../common/client/AuthClient";

export const fetchAccounts = () => {
  return executeGetJsonStreamRequest("/partnership/account");
};

export const fetchContacts = () => {
  return executeGetJsonStreamRequest("/partnership/contact");
};

export const fetchRequests = () => {
  return executeGetJsonStreamRequest("/partnership/request");
};

export const enableContactRequest = (uuid) => {
  return authorisationToken().then((token) =>
    axiosClient.patch(
      "/partnership/enable/" + uuid,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  );
};

export const suspendContactRequest = (uuid) => {
  return authorisationToken().then((token) =>
    axiosClient.patch(
      "/partnership/suspend/" + uuid,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  );
};

export const sendEstablishContactRequest = (uuid) => {
  return authorisationToken().then((token) =>
    axiosClient.post(
      "/partnership/register/" + uuid,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  );
};
