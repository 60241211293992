import CookieConsent from "react-cookie-consent";
import CookieIcon from "@mui/icons-material/Cookie";

const CookieConsentDialog = () => {
  return (
    <CookieConsent
      style={{
        height: "20vh",
        backgroundColor: "#7FC6E6",
      }}
      contentStyle={{
        display: "flex",
        color: "#0d6986",
        padding: "0 5vw",
        height: "15vh",
        alignItems: "center",
        fontStyle: "oblique",
      }}
      buttonText="Accept Cookies"
      buttonStyle={{
        color: "#BFF9E4",
        background: "#0d6986",
        borderRadius: 5,
        padding: "1vw",
        fontSize: "1vw",
      }}
    >
      <CookieIcon style={{ marginRight: "1vw" }} fontSize="large" />
      <label>
        We use cookies to enhance your browsing experience on our website.
        Cookies are small text files that are stored on your device. They help
        us understand how you interact with our site, improve its functionality,
        and personalize your experience. By continuing to browse, you consent to
        the use of cookies.
      </label>
    </CookieConsent>
  );
};

export default CookieConsentDialog;
