import { NavLink } from "react-router-dom";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import styles from "../style/option.module.css";

const AlertOption = () => {
  return (
    <NavLink to={"#"} className={`${styles.option} ${styles.inactive}`}>
      <NotificationImportantOutlinedIcon />
      alert
    </NavLink>
  );
};

export default AlertOption;
