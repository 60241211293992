import { NavLink, Outlet } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import SearchableHeaderView from "../../navigation/component/SearchableHeaderView";
import PanelView from "../../navigation/component/PanelView";
import partnershipSlice from "../redux/partnershipSlice";
import searchSlice, {
  resetSearchPhrase,
  updateSearchPhrase,
} from "../redux/searchSlice";
import { Path } from "../utils/PathUtils";
import styles from "../style/partnership.module.css";

const PartnershipPage = () => {
  const store = configureStore({
    reducer: {
      partnership: partnershipSlice,
      search: searchSlice,
    },
  });

  const navLinkClassName = (props) => {
    return props.isActive
      ? styles.navLink
      : `${styles.navLink} ${styles.inactiveNavLink}`;
  };

  return (
    <Provider store={store}>
      <SearchableHeaderView
        onSearch={(phrase) => store.dispatch(updateSearchPhrase(phrase))}
        onCancel={() => store.dispatch(resetSearchPhrase())}
      >
        <NavLink to={Path.ACCOUNT} className={navLinkClassName}>
          accounts
        </NavLink>
        <NavLink to={Path.CONTACT} className={navLinkClassName}>
          contacts
        </NavLink>
        <NavLink to={Path.REQUEST} className={navLinkClassName}>
          requests
        </NavLink>
      </SearchableHeaderView>
      <PanelView />
      <div className={styles.page}>
        <Outlet />
      </div>
    </Provider>
  );
};

export default PartnershipPage;
