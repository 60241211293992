import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticate } from "../../common/client/AuthClient";
import {
  showLoading,
  hideLoading,
} from "../../common/dialog/ThreeCirclesLoading";

const AuthorisePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    showLoading();
    // extract the authorisation token from the query parameters
    const urlParams = new URLSearchParams(location.hash);
    const code = urlParams.get("code");
    if (code) {
      // if code is present, execute client authorisation
      authenticate(code)
        .then(() => navigate("/"))
        .finally(hideLoading);
    } else {
      // otherwise redirect to home page
      hideLoading();
      navigate("/home");
    }
  }, [location, navigate]);
};

export default AuthorisePage;
