import { NavLink } from "react-router-dom";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import styles from "../style/option.module.css";

const TeamOption = () => {
  return (
    <NavLink
      to={"/team"}
      className={(props) => {
        const stateStyle = props.isActive ? styles.active : styles.inactive;
        return `${styles.option} ${stateStyle}`;
      }}
    >
      <GroupWorkIcon />
      team
    </NavLink>
  );
};

export default TeamOption;
