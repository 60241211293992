import { useState } from "react";
import { ThemeProvider } from "@mui/material";
import NavigationControl from "../component/NavigationControl";
import ValidatedTextField from "./ValidateTextField";
import { InputTheme } from "../../common/utils/ThemeUtils";
import { isValidEmail, isEmpty } from "../utils/ValidationUtils";
import {
  showDialog,
  DialogScope,
} from "../../common/dialog/GenericScopeDialog";
import { ReactComponent as AdminImage } from "../assets/administrator_image.svg";
import styles from "../style/registration.module.css";

const AdministratorForm = (props) => {
  const [username, setUsername] = useState(props.username || props.email);
  const [firstname, setFirstname] = useState(props.firstname);
  const [lastname, setLastname] = useState(props.lastname);

  return (
    <div className={styles.form}>
      <div className={styles.formGroup}>
        <div style={{ padding: "11vh 0" }} className={styles.inputGroup}>
          <ThemeProvider theme={InputTheme}>
            <ValidatedTextField
              label="Username"
              placeholder="Enter the username"
              value={username}
              errorMessage="Username should be represented by a valid email"
              onChange={(value) => {
                setUsername(value);
              }}
              validateRule={(value) => !isValidEmail(value)}
            />
            <ValidatedTextField
              label="First Name"
              placeholder="Enter user first name"
              value={firstname}
              errorMessage="Provide valid first name before proceeding"
              onChange={(value) => {
                setFirstname(value);
              }}
              validateRule={isEmpty}
            />
            <ValidatedTextField
              label="Last Name"
              placeholder="Enter user last name"
              value={lastname}
              errorMessage="Provide valid last name before proceeding"
              onChange={(value) => {
                setLastname(value);
              }}
              validateRule={isEmpty}
            />
          </ThemeProvider>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <AdminImage height={180} />
        </div>
      </div>
      <NavigationControl
        onClick={(event) => {
          if (
            !isValidEmail(username) ||
            isEmpty(firstname) ||
            isEmpty(lastname)
          ) {
            event.preventDefault();
            showDialog(
              "Warning",
              `Please verify the username, first name and last name are accurately completed.`,
              DialogScope.ERROR
            );
          } else {
            props.onClick({ username, firstname, lastname });
          }
        }}
      />
    </div>
  );
};

export default AdministratorForm;
