import { useState } from "react";
import { ThemeProvider } from "@mui/material";
import NavigationControl from "../component/NavigationControl";
import ValidatedTextField from "./ValidateTextField";
import { InputTheme } from "../../common/utils/ThemeUtils";
import { isEmpty } from "../utils/ValidationUtils";
import {
  showDialog,
  DialogScope,
} from "../../common/dialog/GenericScopeDialog";
import { ReactComponent as AddressImage } from "../assets/address_image.svg";
import styles from "../style/registration.module.css";

const AddressForm = (props) => {
  const [country, setCountry] = useState(props.country);
  const [city, setCity] = useState(props.city);
  const [street, setStreet] = useState(props.street);
  const [zipcode, setZipcode] = useState(props.zipcode);

  return (
    <div className={styles.form}>
      <div className={styles.formGroup}>
        <div style={{ padding: "8vh 0" }} className={styles.inputGroup}>
          <ThemeProvider theme={InputTheme}>
            <ValidatedTextField
              label="Country"
              placeholder="Enter the country name"
              value={country}
              errorMessage="Provide valid country before proceeding"
              onChange={(value) => {
                setCountry(value);
              }}
              validateRule={isEmpty}
            />
            <ValidatedTextField
              label="City"
              placeholder="Enter the city name"
              value={city}
              errorMessage="Provide valid city before proceeding"
              onChange={(value) => {
                setCity(value);
              }}
              validateRule={isEmpty}
            />
            <ValidatedTextField
              label="Street"
              placeholder="Enter the street name"
              value={street}
              errorMessage="Provide valid street name before proceeding"
              onChange={(value) => {
                setStreet(value);
              }}
              validateRule={isEmpty}
            />
            <ValidatedTextField
              label="Zip code"
              placeholder="Enter the zip code"
              value={zipcode}
              errorMessage="Provide valid zip code before proceeding"
              onChange={(value) => {
                setZipcode(value);
              }}
              validateRule={isEmpty}
            />
          </ThemeProvider>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <AddressImage height={230} />
        </div>
      </div>
      <NavigationControl
        onClick={(event) => {
          if (
            isEmpty(country) ||
            isEmpty(city) ||
            isEmpty(street) ||
            isEmpty(zipcode)
          ) {
            event.preventDefault();
            showDialog(
              "Warning",
              `Please verify that address details are accurately completed;
              all entries are required to identify the account address.`,
              DialogScope.ERROR
            );
          } else {
            props.onClick({ country, city, street, zipcode });
          }
        }}
      />
    </div>
  );
};

export default AddressForm;
