import { NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import styles from "../style/option.module.css";

const DashboardOption = () => {
  return (
    <NavLink
      to={"/dashboard"}
      className={(props) => {
        const stateStyle = props.isActive ? styles.active : styles.inactive;
        return `${styles.option} ${stateStyle}`;
      }}
    >
      <DashboardIcon />
      dashboard
    </NavLink>
  );
};

export default DashboardOption;
