import SearchableHeaderView from "../../navigation/component/SearchableHeaderView";
import PanelView from "../../navigation/component/PanelView";
import React from "react";

const DashboardPage = () => {
  return (
    <div>
      <SearchableHeaderView onSearch={(phrase) => {}} label="Dashboard" />
      <PanelView />
    </div>
  );
};

export default DashboardPage;
