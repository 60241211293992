import { createTheme } from "@mui/material";

const InputTheme = createTheme({
  palette: {
    primary: {
      main: "#0d6986",
    },
  },
});

export { InputTheme };
