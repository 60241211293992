import { NavLink } from "react-router-dom";
import { clientAuthenticationUrl } from "../../common/client/AuthClient";
import { Path as RegistrationPath } from "../../registration/utils/PathUtils";
import styles from "../style/header.module.css";

const HeaderView = () => {
  return (
    <div className={styles.header}>
      <NavLink to={"/home"} className={styles.logoLabel}>
        shadwork
      </NavLink>
      <div className={styles.menu}>
        <NavLink
          to={"/pricing"}
          className={(props) => {
            return props.isActive ? styles.activeLink : styles.link;
          }}
        >
          Pricing
        </NavLink>
        <NavLink
          to={"/about"}
          className={(props) => {
            return props.isActive ? styles.activeLink : styles.link;
          }}
        >
          About
        </NavLink>
        <NavLink className={styles.link} to={clientAuthenticationUrl}>
          Log In
        </NavLink>
        <NavLink to={RegistrationPath.PROFILE} className={styles.button}>
          Get Started
        </NavLink>
      </div>
    </div>
  );
};

export default HeaderView;
