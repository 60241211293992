import { NavLink } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import styles from "../style/option.module.css";

const SettingsOption = () => {
  return (
    <NavLink
      to={"/settings"}
      className={(props) => {
        const stateStyle = props.isActive ? styles.active : styles.inactive;
        return `${styles.option} ${stateStyle}`;
      }}
    >
      <SettingsOutlinedIcon />
      settings
    </NavLink>
  );
};

export default SettingsOption;
