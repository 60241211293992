import DashboardOption from "../component/DashboardOption";
import ArticleOption from "../component/ArticleOption";
import ChecklistOption from "../component/ChecklistOption";
import OrderOption from "../component/OrderOption";
import TeamOption from "../component/TeamOption";
import ContactOption from "../component/ContactOption";
import LanguageOption from "../component/LanguageOption";
import AlertOption from "../component/AlertOption";
import SettingsOption from "../component/SettingsOption";
import InfoOption from "../component/InfoOption";
import LogoutOption from "../component/LogoutOption";
import { ReactComponent as ShadworkLogo } from "../../common/assets/shadwork-logo.svg";
import styles from "../style/panel.module.css";

const PanelView = () => {
  return (
    <div className={styles.panel}>
      <div className={styles.logo}>
        <ShadworkLogo width="2.5vw" style={{ marginBottom: "1vh" }} />
      </div>
      <div className={styles.navigation}>
        <DashboardOption />
        <ArticleOption />
        <ChecklistOption />
        <OrderOption />
        <TeamOption />
        <ContactOption />
      </div>
      <div className={styles.control}>
        <LanguageOption />
        <AlertOption />
        <SettingsOption />
        <InfoOption />
        <LogoutOption />
      </div>
    </div>
  );
};

export default PanelView;
