import { NavLink } from "react-router-dom";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import styles from "../style/option.module.css";

const LanguageOption = () => {
  return (
    <NavLink to={"#"} className={`${styles.option} ${styles.inactive}`}>
      <LanguageOutlinedIcon />
      language
    </NavLink>
  );
};

export default LanguageOption;
